<template>
  <div class="comm-page">
    <!--comm-main start-->
    <div class="comm-main">
      <div class="agree_pos flexs">
        <router-link to="/personage/honor">个人荣誉 </router-link>
        <span> > </span>
        <div class="agree_a">奖励记录</div>
      </div>
      <!--comm-bd start-->
      <div class="comm-bd">
        <!--agree-head start-->
        <div class="agree-head">
          <div class="key_page_select">
            <div class="select_item">
              <div class="label">时间:</div>
              <div class="select">
                <el-date-picker
                  @change="init"
                  v-model="from.date_time"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="select_item">
              <div class="label">区域:</div>
              <div class="select">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_item">
              <div class="label">门店:</div>
              <div class="select">
                <el-select v-model="value" placeholder="请选择" size="160px">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_item">
              <div class="label">职位:</div>
              <div class="select">
                <el-select v-model="value" placeholder="请选择" size="160px">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_item">
              <div class="label">姓名:</div>
              <div class="select">
                <el-input v-model="from.name" placeholder="请输入"></el-input>
              </div>
            </div>
          </div>
        </div>
        <!--agree-head end-->
        <!--affairs-content start-->
        <div class="affairs-content">
          <table class="affairs-table">
            <tr>
              <th>时间</th>
              <th>区域</th>
              <th>门店</th>
              <th>职位</th>
              <th>姓名</th>
              <th>工号</th>
              <th>获得奖项</th>
            </tr>
            <tr v-for="(item, index) in list" :key="index">
              <td>2020-12-01</td>
              <td>中原区</td>
              <td>华强广场店</td>
              <td>店长</td>
              <td>刘思雨</td>
              <td>125486</td>
              <td>年度之星</td>
            </tr>
          </table>
          <el-table :data="list" border style="width: 100%">
            <el-table-column align="center" header-align="center" prop="date_time" label="时间">
            </el-table-column>
            <el-table-column align="center" header-align="center" prop="city" label="区域">
            </el-table-column>
            <el-table-column align="center" header-align="center" prop="dian_name" label="门店"> </el-table-column>
            <el-table-column align="center" header-align="center" prop="address" label="职位"> </el-table-column>
            <el-table-column align="center" header-align="center" prop="name" label="姓名"> </el-table-column>
            <el-table-column align="center" header-align="center" prop="job_number" label="工号"> </el-table-column>
            <el-table-column align="center" header-align="center" prop="content" label="获得奖项"> </el-table-column>
          </el-table>
          <div class="paging">
            <el-pagination
              background
              layout="prev, pager, next, jumper, ->, total"
              :total="from.total"
            >
            </el-pagination>
          </div>
        </div>
        <!--affairs-content end-->
      </div>
      <!--comm-bd end-->
    </div>
    <!--comm-main end-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          date_time:'2020-12-01',
          name:'刘思雨',
          city:'中原区',
          dian_name:'华强广场店',
          content:'年度之星',
          job_number:'125486'
        }
      ], //列表
      from: {
        page: 1,
        total: 0,
        role_type: 1,
        type: 3, //类型：1 违规；2 晋升；3 获奖
        date_time: "", //时间 格式：2021-02-26
        dian_id: "", //门店id；传id
        position: "", //职位；传：1=区域总监2=店长3=经理4=经纪人
        name: "", //
        area: "",
      },
      options:[],
      value:''
    };
  },
  methods: {
    //初始化
    init() {
      this.from.page = 1;
      this.getList();
    },
    //获取获奖记录
    getList() {
      this.$axios.weiGuiLogList(this.from).then((res) => {
        console.log(res);
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped lang="less">
.agree_pos {
  padding: 5px 0 30px 0;
  color: #666;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 25px;
}
.agree_a {
  color: #3273f6;
  cursor: pointer;
}
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  margin-bottom: 10px;
  height: 64px;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}
.comm-main {
  background: #fff;
  padding: 25px 30px;
  .comm-hd {
    width: 690px;
    height: 210px;
    background: url("../../assets/image/personage/money.png") no-repeat center;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    .c {
      font-size: 42px;
      margin-top: 15px;
    }
    .t {
      font-size: 20px;
    }
  }
  .comm-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 18px;
      padding-left: 10px;
      position: relative;
    }
    .name:before {
      display: block;
      width: 4px;
      height: 20px;
      position: absolute;
      left: 0px;
      top: 50%;
      margin-top: -10px;
      content: "";
      background: #3273f6;
      border-radius: 2px;
    }
    .comm-select {
      /deep/ .el-input {
        width: 160px;
      }
      /deep/ .el-input__inner {
        border: 1px solid #ccc;
        border-radius: 0;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
      }
    }
  }
  .comm-list {
    .comm-item {
      display: flex;
      border-bottom: 1px solid #ebebeb;
      padding: 15px 0px;
      justify-content: space-between;
      align-items: center;
      .item-l {
        display: flex;
        align-items: center;
        width: calc(100% - 150px);
        .icon {
          width: 44px;
          height: 44px;
        }
        .txt {
          margin-left: 20px;
          .t {
            font-size: 16px;
            line-height: 1.6;
          }
          .b {
            display: flex;
            font-size: 14px;
            line-height: 1.6;
            margin: 5px -20px;
          }
          .b-s {
            line-height: 1.6;
            margin: 0 20px;
          }
        }
      }
      .d {
        color: #999;
        font-size: 14px;
        margin-top: 5px;
        line-height: 1.6;
      }
      .item-r {
        width: 150px;
        text-align: right;
        .add-num {
          font-size: 24px;
          color: #ff2121;
        }
      }
    }
  }
}
.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
  .select {
    width: 200px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
  /deep/ .el-date-editor.el-input {
    width: 200px;
  }
}
.affairs-content {
  margin: 20px 10px;
}
.affairs-table {
  width: 100%;
  border: 1px solid #ebebeb;
  border-collapse: collapse;
}
.affairs-table th {
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-top: none;
  border-bottom: none;
  padding: 15px 10px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}
.affairs-table td {
  padding: 15px 10px;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  text-align: center;
  font-size: 14px;
}
.affairs-table-btn {
  display: flex;
  .btn {
    padding: 0px;
    background: none;
    outline: none;
    margin: 5px;
    border: none;
  }
  .color-orange {
    color: #ff9600;
  }
  .color-red {
    color: #ff5151;
  }
}
.affairs-table tr td {
  background: #f7fcff;
}
.affairs-table tr:nth-child(2n) td {
  background: #fff;
}
</style>
